div {
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
}

div:active {
  background-color: inherit;
}

body {
  --soft-white: antiquewhite;
  --bg-color: black;
  --fg-color: var(--soft-white);
  --fg-dim-color: gray;
  --fg-c-dim-color: black;
  --fg-y-color: ivory;
  --g-background-color: darkblue;
  --s-background-color: #181818;
  --nothere-color: chocolate;
  --fix-green-on-color: green;
  --fix-green-off-color: #004000;
  --meta-color: #bf0101;
  --meta-background-color: #190001;
  --meta-disabled-color: #540000;
  --meta-paused-color: dimgray;
  --nothere-fg-color: #d3691e;
  --nowhere-fg-color: #540000;
  --infeasible-fg-color: mediumpurple;
  --unrestricted-fg-color: gainsboro;
  --pending-fg-color: lime;
  --pended-fg-color: green;
  --seq-g-color: #aaaaaa;
  --shadow-color: #666666;
  --dot-color: #666666;
  background-color: var(--bg-color);
  color: var(--fg-color);
}

@media(prefers-color-scheme: light) {
  body {
    --bg-color: #e9e9e4;
    --fg-color: black;
    --fg-y-color: black;
    --g-background-color: green;
    --s-background-color: #d4d4cf;
    --nothere-color: chocolate;
    --fix-green-on-color: #bfdfbf;
    --fix-green-off-color: #008000;
    --meta-color: #ff4040;
    --meta-background-color: #efe7e7;
    --meta-disabled-color: #ffbfbf;
    --meta-paused-color: lightslategray;
    --nothere-fg-color: #d2691e;
    --nowhere-fg-color: #cbcbcb;
    --infeasible-fg-color: mediumpurple;
    --unrestricted-fg-color: black;
    --pending-fg-color: #00CED1;
    --pended-fg-color: #6A5ACD;
    --seq-g-color: var(--soft-white);
    --shadow-color: #666666;
    --dot-color: #666666;
    background-color: var(--bg-color);
    color: var(--fg-color);
  }
}

@media (pointer: coarse), (hover: none), (hover: on-demand) {
  div#root {
    position: fixed;
    bottom: 16px;
    left: 2px;
    right: 2px;
  }
  div.error {
    position: fixed;
    top: 0px;
    padding: 4px;
  }
  div.error.bottom {
    top: auto;
    bottom: 0px;
    font-size: 10px;
  }
}

div#root {
  font-family: monospace;
  display: flex;
  flex-direction: column-reverse;
  justify-items: center;
  align-items: center;
  gap: 1px;
}

div.spacer {
  padding: 10px;
}

div.sliver {
  padding: 0px;
}

App {
  display: inherit;
  justify-items: center;
}

div.a {
  display: grid;
  position: relative;
  margin-top: 4px;
}

div.a.first {
  border-top: 1px solid gray;
  padding-top: 5px;
  margin-top: 0px;
}

div.b {
  width: 48px;
  height: 48px;
  display: grid;
  position: relative;
  align-items: center;
  justify-content: center;
  outline: none;
  font-size: 2rem;
  transition: background-color 0.1s;
  border-bottom: 1px solid gray;
}

div.b.shrink {
  width: 38px;
  height: 38px;
  font-size: 1.75rem;
}

div.b.shadow {
  color: var(--shadow-color);
}

div.b.dot {
  color: var(--dot-color);
}

div.b.mc-violation {
  color: orange;
}

div.b.mc-violation-mild {
  color: mediumpurple;
}

div.b.capped-violation {
  color: red;
}

div.a.dim > div.b {
  color: var(--fg-dim-color);
}

div.a.dim > div.b.c {
  color: var(--fg-c-dim-color);
}

div.c {
  background-color: gray;
  transition: background-color 0.3s;
}

div.d {
  background-color: gray;
  transition: background-color 0.1s;
}

div.x {
  background-color: var(--g-background-color);
  transition: background-color 0.1s;
}

div.y {
  background-color: chocolate;
  transition: background-color 0.1s;
}

div.Y {
  background-color: darkgoldenrod;
  color: var(--fg-y-color);
}

div.G {
  background-color: var(--g-background-color);
}

div.S {
  border-bottom: none;
}

div.Z {
  color: var(--meta-disabled-color);
}

div.title {
  font-size: 1.5em;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

div.title > div.handle {
  float: left;
}

div.title > div.share {
  float: right;
}

div.title > div.word_count {
  float: right;
  font-size: 14px;
}

div.title > div.handle > span.at, div.title > div.share > span.slash, div.title > div.word_count {
  color: gray;
}

div.end_game_div {
  display: flex;
  position: relative;
  width: 240px;
  justify-content: center;
}

.end_game {
  background-color: chocolate;
  color: currentColor;
  margin: 10px;
  padding: 0px 48px 0px 48px;
  font-family: inherit;
  font-size: 2em;
  border: none;
  border-radius: 1em;
  transition: background-color 0.2s;
}

.end_game:disabled {
  background-color: gray;
  transition: background-color 0.2s;
}

.end_game.minor {
  position: absolute;
  right: 0;
  background-color: darkred;
  color: var(--soft-white);
  padding: 0px 10px 0px 10px;
  font-family: inherit;
  font-size: 1.5em;
  border: none;
  border-radius: 1em;
  transition: background-color 0.2s;
}

.end_game.minor.feedback {
  margin: 5px;
  background-color: transparent;
  font-size: 2rem;
}

.end_game.minor:disabled {
  background: gray;
}

.end_game.minor.left {
  right: auto;
  left: 0;
}

div.invalid {
  position: relative;
}

button.feedback_emoji {
  background: transparent;
  border: none;
  font-size: 2.0em;
  padding: 0;
}

button.close {
  z-index: 1;
  position: absolute;
  padding: 0;
  top: 0;
  right: 0;
  background-color: var(--meta-background-color);
  color: var(--meta-color);
  font-family: inherit;
  font-size: 1.5em;
  border: none;
  transition: background-color 0.2s;
}

button.copy {
  z-index: 1;
  position: absolute;
  padding: 2px;
  top: 0;
  right: 0;
  display: flex;
  color: var(--soft-white);
  background-color: darkred;
  border-radius: 8px;
  font-family: inherit;
  font-size: 1.5em;
  border: none;
  transition: background-color 0.2s;
}

div.seq, div.rep, div.n, div.vsize, div.clue_seen {
  position: absolute;
  color: gray;
  border-radius: 2px;
  font-weight: bold;
}

div.seq, div.rep, div.n, div.vsize {
  font-size: 10px;
}

div.clue_seen {
  font-size: 12px;
}

div.seq, div.rep, div.vsize {
  top: -1px;
}

div.clue_seen {
  bottom: 0;
}

div.n {
  bottom: 0;
}

div.n {
  font-size: 12px;
}

div.seq, div.n, div.clue_seen {
  right: 0;
}

div.vestigealvsize {
  font-size: 9px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  transform: translate(-50%, +50%) rotate(-90deg) translate(+50%, +50%) translate(1px, 0px);
}

div.rep, div.vsize, div.exhausted {
  left: 0;
}

div.rep, div.exhausted {
  font-size: 10px;
}

div.vsize {
  font-size: 10px;
}

div.Y > div.seq, div.Y > div.rep, div.Y > div.vsize, div.Y > div.clue_seen, div.a.dim > div.b.c > div.seq, div.a.dim > div.b.c > div.rep, div.a.dim > div.b.c > div.vsize, div.a.dim > div.b.c > div.clue_seen {
  color: black;
}

div.G > div.seq, div.G > div.rep, div.G > div.clue_seen {
  color: var(--seq-g-color);
}

div.G > div.vsize {
  color: chartreuse;
}

div.keyboard > button.meta.sharing {
  color: var(--soft-white);
  background-color: darkred;
}

div.keyboard > button.meta.sharing.dim {
  filter: brightness(50%);
}

div.keyboard {
  display: grid;
  gap: 1px;
  grid-template-columns: repeat(20, 1fr);
  min-width: min(500px, 100%);
}

div.keyboard > button {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 12px;
  padding-bottom: 8px;
  background-color: inherit;
  color: inherit;
  border-style: none;
  outline: solid 1px;
  outline-color: gray;
  font-size: 1.0rem;
}

div.keyboard > button.svg {
  padding: 0px;
}

div.keyboard > button.caps {
  text-transform: uppercase;
}

div.keyboard > button.gapped_row {
  margin-top: 12px;
}

div.keyboard > button.fix_green.on {
  color: var(--fix-green-on-color);
  transition: color 0.2s;
}

div.keyboard > button.fix_green.off {
  color: var(--fix-green-off-color);
  transition: color 0.2s;
}

div.keyboard > button.meta.enlarge {
  padding: 0;
  font-size: 2rem;
}

div.keyboard > button.meta {
  color: var(--meta-color);
  background-color: var(--meta-background-color);
  transition: color 0.2s;
}

div.keyboard > button.letter, div.keyboard > button.enter, div.keyboard > button.stab, div.keyboard > button.question {
  color: var(--unrestricted-fg-color);
  transition: color 0.2s;
  font-weight: unset;
}

div.keyboard > button.stab, div.keyboard > button.question {
  font-weight: bold;
}

div.keyboard > button.meta.paused {
  color: var(--meta-paused-color);
  transition: color 0.2s;
  font-weight: unset;
}

div.keyboard > button.meta:disabled {
  color: var(--meta-disabled-color);
  transition: color 0.2s;
  font-weight: unset;
}

div.keyboard > button.nowhere.disabled {
  color: var(--nowhere-fg-color);
  transition: color 0.2s;
  font-weight: unset;
}

div.keyboard > button.not_here {
  color: goldenrod;
  transition: color 0.2s;
  font-weight: unset;
}

div.keyboard > button.not_here.disabled {
  color: var(--nothere-fg-color);
  transition: color 0.2s;
  font-weight: unset;
}

div.keyboard > button.infeasible {
  color: var(--infeasible-fg-color);
  transition: color 0.2s;
  font-weight: unset;
}

div.keyboard > button.pending {
  color: var(--pending-fg-color);
  transition: color 0.2s;
  font-weight: unset;
}

div.keyboard > button.pended {
  color: var(--pended-fg-color);
  transition: color 0.2s;
  font-weight: unset;
}

div.keyboard.noclue {
  padding-top: 21px;
}

div.keyboard.clue {
  padding-top: 0px;
}

div.clue {
  padding-top: 2px;
  font-size: 18px;
}

